<template>
  <b-container fluid class="content">
    <div v-if="curpage == 'list'">
      <b-card>
        <b-form>
          <el-row :gutter="10" style="margin-bottom: 10px">
            <el-col :span="6">
              <b-input-group :prepend="$t('Start Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_start_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('End Date')">
                <flat-pickr
                  :config="picker_config2"
                  class="form-control datepicker"
                  v-model="cur_end_dt"
                >
                </flat-pickr>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Order#') + ' : '">
                <b-form-input v-model="cur_order_no"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Transaction#') + ' : '">
                <b-form-input v-model="cur_transaction_no"></b-form-input>
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <SearchAutocomplete
                :url="apiBaseUrl + '/Web/product/search_allforlist'"
                :placeholdertxt="$t('PLU/Barcode/Name') + ' : '"
                id="list_search_product"
                @input="set_cur_product_name4"
              ></SearchAutocomplete>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Store') + ' : '">
                <select class="form-control" v-model="cur_LocationID">
                  <option value=""></option>
                  <option
                    v-for="store in store_list"
                    :key="store.LocationID"
                    :value="store.LocationID"
                  >
                    {{ store.LocationName }}
                  </option>
                </select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Remarks') + ' : '">
                <b-form-input v-model="cur_comment"></b-form-input>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <el-button
                type="primary"
                class="mt-2"
                icon="el-icon-search"
                size="small"
                @click="get_lists"
                >{{ $t("Search") }}</el-button
              >
            </el-col>
          </el-row>
        </b-form>
      </b-card>
      <!-- list -->
      <b-card>
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <!-- pending -->
          <el-tab-pane :label="$t('Pending')" name="Pending">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_order_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_order"
            >
              <el-table-column
                label="Location"
                prop="location_id"
                class="p-0"
                min-width="60px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Location") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.location_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="order_id"
                class="p-0"
                min-width="80px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Platform No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.order_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="TransactionNo"
                prop="TransactionNo"
                class="p-0"
                min-width="120px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Transaction No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.TransactionNo }}
                </template>
              </el-table-column>
              <el-table-column label="date_added" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.date_added.substr(0, 16) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.firstname + ' ' + row.lastname
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="total"
                prop="total"
                class="p-0"
                align="center"
                min-width="60px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    parseFloat(row.total).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="shipping_method"
                prop="shipping_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Method2") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.shipping_code == 'pickup.pickup' ? $t('Pick Up2') : $t('Delivery')
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="payment_method"
                prop="payment_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Payment") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="comment"
                prop="comment"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- complete -->
          <el-tab-pane :label="$t('Complete')" name="Complete">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_order_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_order"
            >
              <el-table-column
                label="Location"
                prop="location_id"
                class="p-0"
                min-width="60px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Location") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.location_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="order_id"
                class="p-0"
                min-width="80px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Platform No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.order_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="TransactionNo"
                prop="TransactionNo"
                class="p-0"
                min-width="120px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Transaction No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.TransactionNo }}
                </template>
              </el-table-column>
              <el-table-column label="date_added" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.date_added.substr(0, 16) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.firstname + ' ' + row.lastname
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="total"
                prop="total"
                class="p-0"
                align="center"
                min-width="60px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    parseFloat(row.total).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="shipping_method"
                prop="shipping_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Method2") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.shipping_code == 'pickup.pickup' ? $t('Pick Up2') : $t('Delivery')
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="payment_method"
                prop="payment_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Payment") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="comment"
                prop="comment"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- confirmed -->
          <el-tab-pane :label="$t('Confirmed')" name="Confirmed">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_order_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_order"
            >
              <el-table-column
                label="Location"
                prop="location_id"
                class="p-0"
                min-width="60px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Location") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.location_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="order_id"
                class="p-0"
                min-width="80px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Platform No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.order_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="TransactionNo"
                prop="TransactionNo"
                class="p-0"
                min-width="120px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Transaction No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.TransactionNo }}
                </template>
              </el-table-column>
              <el-table-column label="date_added" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.date_added.substr(0, 16) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.firstname + ' ' + row.lastname
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="total"
                prop="total"
                class="p-0"
                align="center"
                min-width="60px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    parseFloat(row.total).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="shipping_method"
                prop="shipping_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Method2") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.shipping_code == 'pickup.pickup' ? $t('Pick Up2') : $t('Delivery')
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="payment_method"
                prop="payment_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Payment") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="comment"
                prop="comment"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- shipped -->
          <el-tab-pane :label="$t('Shipped')" name="Shipped">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_order_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_order"
            >
              <el-table-column
                label="Location"
                prop="location_id"
                class="p-0"
                min-width="60px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Location") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.location_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="order_id"
                class="p-0"
                min-width="80px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Platform No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.order_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="TransactionNo"
                prop="TransactionNo"
                class="p-0"
                min-width="120px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Transaction No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.TransactionNo }}
                </template>
              </el-table-column>
              <el-table-column label="date_added" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.date_added.substr(0, 16) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.firstname + ' ' + row.lastname
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="total"
                prop="total"
                class="p-0"
                align="center"
                min-width="60px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    parseFloat(row.total).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="shipping_method"
                prop="shipping_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Method2") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.shipping_code == 'pickup.pickup' ? $t('Pick Up2') : $t('Delivery')
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="payment_method"
                prop="payment_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Payment") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="comment"
                prop="comment"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <!-- Canceled -->
          <el-tab-pane :label="$t('Canceled')" name="Canceled">
            <el-table
              stripe
              class="table-responsive table product-table mt-2"
              style="width: 100%"
              header-row-class-name="thead-light"
              :data="cur_order_list"
              :cell-style="{ textAlign: 'center' }"
              @row-click="edit_order"
            >
              <el-table-column
                label="Location"
                prop="location_id"
                class="p-0"
                min-width="60px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Location") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.location_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="OrderNo"
                prop="order_id"
                class="p-0"
                min-width="80px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Platform No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.order_id }}
                </template>
              </el-table-column>
              <el-table-column
                label="TransactionNo"
                prop="TransactionNo"
                class="p-0"
                min-width="120px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Transaction No.") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{ row.TransactionNo }}
                </template>
              </el-table-column>
              <el-table-column label="date_added" class="p-0" min-width="100px">
                <template slot="header">
                  <div class="w-100 p-0 text-center">
                    {{ $t("Order Date") }}
                  </div>
                </template>
                <template v-slot="{ row }">
                  <div class="w-100 p-0 text-center">
                    {{ row.date_added.substr(0, 16) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Customer"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Customer") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.firstname + ' ' + row.lastname
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="total"
                prop="total"
                class="p-0"
                align="center"
                min-width="60px"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    parseFloat(row.total).toFixed(2)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="shipping_method"
                prop="shipping_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Shipping Method2") }}</div>
                </template>
                <template v-slot="{ row }">
                  {{
                    row.shipping_code == 'pickup.pickup' ? $t('Pick Up2') : $t('Delivery')
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="payment_method"
                prop="payment_method"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Payment") }}</div>
                </template>
              </el-table-column>
              <el-table-column
                label="comment"
                prop="comment"
                class="p-0"
                min-width="100px"
                align="center"
              >
                <template slot="header">
                  <div class="w-100 p-0 text-center">{{ $t("Remarks") }}</div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-row style="margin-top: 20px">
          <el-col :span="20">
            <div>
              <b-pagination
                v-model="current_page"
                :total-rows="page_total"
                :per-page="per_page"
              ></b-pagination>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ $t("Max Rows per page") }} {{ this.per_page
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="page_option in per_page_option"
                    :key="page_option"
                    :command="page_option"
                    >{{ page_option }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </b-card>
    </div>
    <div v-if="curpage == 'edit'">
      <div style="margin-top: 5px;margin-bottom: 5px">
        <el-button type="primary" @click="cancel_click">
          {{ $t("Back") }}
        </el-button>
        <el-button type="primary" @click="refund_click" v-if="(cur_order.need_refund != 1 && cur_order.is_refund != 1 && cur_order.is_online == 1 && cur_order.is_paid == 1)">
          {{ $t("Refund") }}
        </el-button>
      </div>
      <el-collapse v-model="activeCollapse1">
        <el-collapse-item :title="$t('Base')" name="base">
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Location') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.location_id"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Platform No.') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.order_id"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Transaction No.') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.TransactionNo"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Transaction ID') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.TransactionIDn"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Status') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.order_status"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Total') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="parseFloat(cur_order.total).toFixed(2)"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Currency') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.currency_code"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Remarks') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.comment"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Order Time') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.date_added"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Update Time') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.date_modified"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Language') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.language_code"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item :title="$t('Customer')" name="customer">
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('First Name') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.firstname"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Last Name') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.lastname"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Email') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.email"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Tel') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.telephone"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item :title="$t('Payment')" name="payment">
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Payment Method') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.payment_method"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="'Code'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.payment_code"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Is Online') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="cur_order.is_online ? 'Yes' : 'No'"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Is Paid') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="cur_order.is_paid ? 'Yes' : 'No'"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Need Refund') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="cur_order.need_refund ? 'Yes' : 'No'"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Is Refunded') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="cur_order.is_refund ? 'Yes' : 'No'"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Refund Remarks') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.refund_remark"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Payment Text') + ':'">
                <b-textarea
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  v-model="cur_order.pay_detail"
                  disabled
                >
                </b-textarea>
              </b-input-group>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item :title="$t('Shipping2')" name="shipping">
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Shipping Method2') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="cur_order.shipping_code == 'pickup.pickup' ? $t('Pick Up2') : $t('Delivery')"
                  disabled
                />
              </b-input-group>
            </el-col>
            <!-- period -->
            <el-col :span="6" v-if="cur_order.shipping_code == 'pickup.pickup'">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Period') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.pickup_period"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6" v-else>
              <b-input-group class="mb-2 mt-2" :prepend="$t('Period') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.delivery_period"
                  disabled
                />
              </b-input-group>
            </el-col>
            <!-- delivery tip -->
            <el-col :span="6" v-if="cur_order.delivery_tip_type == 'fix'">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Delivery Tip') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="'$'+cur_order.delivery_tip_fix"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6" v-else>
              <b-input-group class="mb-2 mt-2" :prepend="$t('Delivery Tip') + ':'">
                <b-input
                  class="form-control left_padding"
                  :value="cur_order.delivery_tip_percent+'%'"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('First Name') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_firstname"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Last Name') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_lastname"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Address1') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_address_1"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Address2') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_address_2"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('City') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_city"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Province') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_zone"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Country') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_country"
                  disabled
                />
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group class="mb-2 mt-2" :prepend="$t('Post Code') + ':'">
                <b-input
                  class="form-control left_padding"
                  v-model="cur_order.shipping_postcode"
                  disabled
                />
              </b-input-group>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item :title="$t('Product List')" name="product">
          <el-table
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            id="product_list"
            header-row-class-name="thead-light"
            :cell-style="{ textAlign: 'center' }"
            :data="cur_order_detail.products"
            :style="{ marginBottom: 1.5 + 'rem' }"
          >
            <el-table-column prop="pk_product_idn" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("PK Product ID") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="name" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Name") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="quantity" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Qty") }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="price" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Price") }}</div>
              </template>
              <template v-slot="{ row }">
                {{ parseFloat(row.price).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="total" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Total") }}</div>
              </template>
              <template v-slot="{ row }">
                {{ parseFloat(row.total).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="tax" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Tax") }}</div>
              </template>
              <template v-slot="{ row }">
                {{ parseFloat(row.tax).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="options" class="p-0" min-width="150px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ $t("Modifier") }}</div>
              </template>
              <template v-slot="{ row }">
                <b-textarea
                  type="textarea"
                  rows="1"
                  max-rows="1"
                  :value="format_options(row.options)"
                  no-resize
                  disabled
                ></b-textarea>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
        <el-collapse-item :title="$t('Total')" name="total">
          <el-table
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            header-row-class-name="thead-light"
            :cell-style="{ textAlign: 'center' }"
            :data="cur_order_detail.totals"
            :style="{ marginBottom: 1.5 + 'rem' }"
          >
            <el-table-column prop="code" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ 'Code' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="title" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ 'Title' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="value" class="p-0" min-width="100px">
              <template slot="header">
                <div class="w-100 p-0 text-center">{{ 'Value' }}</div>
              </template>
              <template v-slot="{ row }">
                {{ parseFloat(row.value).toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>

    <b-modal ref="refund_modal" :title="$t('Refund')" id="refund_modal">
      <div>{{ $t('Remarks') }}:</div>
      <b-textarea
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 4}"
        v-model="cur_order.refund_remark_tmp"
      >
      </b-textarea>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-primary"
          @click="refund_confirm"
        >
          {{ $t('Confirm') }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          @click="close_refund_modal"
        >
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import {
  Card,
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Autocomplete,
  Radio,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "element-ui";
import SearchAutocomplete from "../Components/SearchAutocomplete";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Autocomplete.name]: Autocomplete,
    [Radio.name]: Radio,
    [flatPicker.name]: flatPicker,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    SearchAutocomplete,
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_lists();
    },
  },
  data() {
    return {
      token: "",
      user_id: "",
      current_page: 1,
      page_total: 0,
      per_page: 20,
      per_page_option: [10, 20, 50],
      delay_timer: null,
      order_delay_timer: null,
      order_show_name2: false,
      curpage: 'list',
      activeName: "Complete", //  Pending Complete Shipped Canceled Confirmed
      activeCollapse1: ['base', 'customer', 'shipping', 'payment', 'product', 'total'],

      // Search condition
      cur_start_dt: "",
      cur_end_dt: "",
      cur_order_no: "",
      cur_transaction_no: "",
      cur_comment: "",
      cur_product_name4: "",
      cur_LocationID: "",

      cur_order_list: [],
      cur_order_meta: {},
      store_list: null,
      sales_list: [],

      cur_order: {},
      cur_order_detail: [],

      picker_config2: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: "today", // New Date();  YYYY-MM-DD HH:MM
      },
      pickr_config: {
        enableTime: false,
        noCalendar: false,
        dateFormat: "Y-m-d",
        // time_24hr: true
      },
    };
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    set_cur_product_name4(val) {
      this.cur_product_name4 = val;
    },
    tabClick(tab, event) {
      this.current_page = 1;
      this.page_total = 0;
      this.per_page = 20;
      this.cur_LocationID = "";
      this.cur_product_name4 = "";
      this.cur_comment = "";
      this.cur_order_no = "";
      this.cur_transaction_no = "";
      this.get_lists();
    },
    async get_lists() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      var research = 0;
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_order_no) {
        research = 1;
        bodyFormData.append("order_id", this.cur_order_no);
      }
      if (this.cur_transaction_no) {
        research = 1;
        bodyFormData.append("TransactionNo", this.cur_transaction_no);
      }
      if (this.cur_comment) {
        research = 1;
        bodyFormData.append("comment", this.cur_comment);
      }
      if (this.cur_product_name4) {
        research = 1;
        bodyFormData.append("Name4", this.cur_product_name4);
      }
      if (this.cur_LocationID) {
        research = 1;
        bodyFormData.append("location_id", this.cur_LocationID);
      }
      bodyFormData.append("order_status", this.activeName);
      if (research) {
        bodyFormData.append("research", 1);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/OnlineOrder/list",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.order_status != undefined) {
                that.activeName = rt.data.order_status;
              }
              that.cur_order_list = rt.data.list_list;
              that.cur_order_meta = rt.data.list_meta;
              if (that.cur_order_meta.cnt != undefined) {
                that.page_total = that.cur_order_meta.cnt;
                that.per_page = rt.data.per_page;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_init_data() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      if (this.cur_start_dt) {
        bodyFormData.append("start_dt", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("end_dt", this.cur_end_dt);
      }
      if (this.cur_order_no) {
        bodyFormData.append("order_id", this.cur_order_no);
      }
      if (this.cur_transaction_no) {
        bodyFormData.append("TransactionNo", this.cur_transaction_no);
      }
      if (this.cur_comment) {
        bodyFormData.append("comment", this.cur_comment);
      }
      if (this.cur_LocationID) {
        bodyFormData.append("location_id", this.cur_LocationID);
      }
      bodyFormData.append("order_status", this.activeName);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/OnlineOrder",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_order_list = rt.data.list_list;
              that.cur_order_meta = rt.data.list_meta;
              if (that.cur_order_meta.cnt != undefined) {
                that.page_total = that.cur_order_meta.cnt;
                that.per_page = rt.data.per_page;
              }

              that.store_list = rt.data.store_list;
              that.sales_list = rt.data.sales_list;
              that.order_show_name2 = rt.data.order_show_name2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_extra() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("order_id", this.cur_order.order_id);
      bodyFormData.append("location_id", this.cur_order.location_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/OnlineOrder/extra",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_order_detail = rt.data.detail;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    edit_order(row, event, column) {
      this.cur_order = JSON.parse(JSON.stringify(row));
      this.get_extra();
      this.curpage = 'edit';
      window.scrollTo(0,0);
    },
    handleCommand(selected) {
      this.per_page = selected;
      this.current_page=1;
      this.get_lists();
    },
    cancel_click() {
      this.curpage = "list";
    },
    refund_click() {
      if (!(this.cur_order.need_refund != 1 && this.cur_order.is_refund != 1 && this.cur_order.is_online == 1 && this.cur_order.is_paid == 1)) {
        alert('Only online paid order can do refund');
        return;
      }
      this.cur_order.refund_remark_tmp = '';
      this.$refs["refund_modal"].show();
    },
    close_refund_modal() {
      this.$refs["refund_modal"].hide();
    },
    refund_confirm() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("order_id", this.cur_order.order_id);
      bodyFormData.append("location_id", this.cur_order.location_id);
      bodyFormData.append("remark", this.cur_order.refund_remark_tmp);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Sales/OnlineOrder/refund",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.$refs["refund_modal"].hide();
              alert("Refund success, it takes several minutes to process");
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    format_options(options) {
      var tmp = [];
      for (var i = 0; i < options.length; i++) {
        tmp.push((i+1) + ". " + options[i].name + " " + options[i].value + " " + options[i].quantity + " $" + options[i].price);
      }
      return tmp.join('\n');
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    if (!this.token || !this.user_id) {
      this.$router.push("/login");
      return;
    }
    this.get_init_data();
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
hr.solid {
  border-top: 1px solid #bbb;
}
.input-label {
  display: inline-block;
  width: 130px;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1rem;
  padding-left: 2rem;
  height: 2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}

.addressinfo .el-collapse-item__content,
.productlist .el-collapse-item__content,
.sodetail .el-collapse-item__content {
  padding: 0;
}
.product_popup {
  padding: 2%;
  position: fixed;
  top: calc(50% - 21rem);
  left: 15%;
  height: 42rem;
  width: 70%;
  z-index: 10;
  background: white;
}
.group-append-text .input-group-text {
  flex: 1;
}

.product-table th,
.product-table td {
  padding: 0.1rem !important;
}
.product-table th div,
.product-table td div {
  padding: 0.1rem !important;
  line-height: 1.25rem;
  overflow: visible;
}

#search_modal .modal-title,
#import_modal .modal-title {
  font-size: 1.5rem;
}
#search_modal hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.search_product_section {
  max-height: 12rem;
  overflow: auto;
}
.search_product_section .el-table td,
.el-table th {
  padding: 2px 0;
}
.product_row {
  border-top: 1px solid gray;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.btn-inner--icon .icon-add {
  color: green;
}
.btn-inner--icon .icon-remove {
  color: red;
}
.order_title {
  font-weight: bold;
  font-size: 1.2rem;
}
.order_card_title {
  font-weight: 500;
  font-size: 1.2rem !important;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
.so-edit .el-card__header {
  padding: 0.1rem 1rem;
}
.so-edit div {
  font-size: 1rem;
}
.so-edit .el-card__body {
  padding: 0.2rem;
}
.so-edit .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.left_padding {
  padding-left: 15px !important;
}
</style>